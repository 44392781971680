import React, { lazy, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { BrowserScrollService } from "@utils/BrowserScrollService";
import { StorageKeys } from "@utils/LocalStorage";
import {  getLoggedInUserId, handleAuthFlow } from "@utils/UserUtils";

import { ProfileModel } from "@common/domain/models/Profile";
import { isAuthenticatedSelector } from "@common/redux/auth/selectors";
import { getProfileByUserIdSelector, getProfileMap } from "@common/redux/profile/selectors";

import { guestUserLangCode } from "@translations/AppLanguage";
import { i18n } from "@translations/i18n";

import ErrorBoundary from "@components/molecules/ErrorBoundary";
import Spinner from "@components/old/atoms/Spinner";

import Navigator from "@navigator/index";
import { NavigationUrl, URLs } from "@navigator/NavigationUrl";
import { Routes } from "@navigator/Routes";

import { LocalizedRouter } from "@router/components/LocalizedRouter";
import { LocalizedSwitch } from "@router/components/LocalizedSwitch";
import { RoutePrivate } from "@router/RoutePrivate";
import RoutePublic from "@router/RoutePublic";
import { LanguageService } from "@webServices/LanguageService";
import { ConnectedRouter } from "connected-react-router";
import { platform } from "@constants/config";

// // Auth
const SelfRegisterContainer = lazy(() => import(/* webpackChunkName: "SelfRegisterContainer" */ "@containers/Auth/SelfRegisterContainer"));

const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ "@containers/Auth/ResetPasswordContainer")
);
const ChangePassword = lazy(() =>
  import(/* webpackChunkName: "ChangePassword" */ "@containers/Auth/CreatePasswordContainer")
);
const ForgotPassword = lazy(() =>
  import(/* webpackChunkName: "ForgotPassword" */ "@containers/Auth/ForgotPasswordContainer")
);
const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ "@containers/Auth/LogoutContainer"));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ "@containers/Auth/LoginContainer"));
const RegisterUser = lazy(() =>
  import(/* webpackChunkName: "RegisterUserContainer" */"@containers/Auth/RegisterUserContainer"));

const SupportView = lazy(() =>
  import(/* webpackChunkName: "UserSupport" */"@presentation/UserSupport")
);
const TnC = lazy(() => import(/* webpackChunkName: "TnC" */"@containers/Auth/TnCContainer"));

const GuestDashboard = lazy(() =>
  import(/* webpackChunkName: "GuestDashboard" */"@presentation/GuestDashboard")
);
const Typography = lazy(() => import("@presentation/Typography/index"));

const GuestView = lazy(() => import(/* webpackChunkName: "GuestView" */"@containers/Guest/GuestViewContainer"));
const RefreshCookies = lazy(() =>
  import(/* webpackChunkName: "RefreshCookiesContainer" */"@containers/RefreshCookies/RefreshCookiesContainer")
);
const PageNotFound = lazy(() =>
  import(/* webpackChunkName: "PageNotFoundContainer" */"@containers/PageNotFound/PageNotFoundContainer")
);

// chat
const ChatContainer = lazy(() => import(/* webpackChunkName: "ChatContainer" */"@containers/Chat/ChatContainer"));

const CourseDetails = lazy(() =>
  import(/* webpackChunkName: "CourseDetailsContainer" */"@containers/CourseDetailsContainer/CourseDetailsContainer")
);
const ConnectDiscourse = lazy(() =>
  import(/* webpackChunkName: "ConnectDiscourseContainer" */"@containers/CourseDetailsContainer/ConnectDiscourseContainer")
);
// calendar
const CalendarContainer = lazy(() =>
  import(/* webpackChunkName: "CalendarContainer" */"@containers/Calendar/CalendarContainer")
);

// event
const MeetingDetailContainer = lazy(() =>
  import(/* webpackChunkName: "MeetingDetailContainer" */"@containers/Meeting/MeetingDetailContainer")
);

// // profile page
const ProfileContainer = lazy(() => import(/* webpackChunkName: "ProfileContainer" */'@containers/Profile/ProfileContainer'));

// Job Prepare Page
const JobPrepare = lazy(() => import(/* webpackChunkName: "JobPrepare" */"@presentation/Interview/components/WelcomeLandingPage/components/JobPrepare"));

// Interview Page
const Interview = lazy(() => import(/* webpackChunkName: "Interview" */"@presentation/Interview"));
const InterviewHistory = lazy(() => import(/* webpackChunkName: "InterviewHistory" */"@presentation/Interview/components/InterviewHistory"));

const Home = lazy(() => import(/* webpackChunkName: "Home" */"@presentation/Home"));
// const GuestView = lazy(() => import("@presentation/GuestView"));
const OpenCourses = lazy(() => import(/* webpackChunkName: "OpenCourses" */"@presentation/OpenCourses/index.jsx"));
const SsoLogin = lazy(() => import(/* webpackChunkName: "SsoLoginContainer" */"@containers/SsoLogin/SsoLoginContainer"));

//batch
const CreateBatch = lazy(() => import(/* webpackChunkName: "CreateBatch" */'@containers/Batch/CreateBatchContainer'));
const BatchesList = lazy(() => import(/* webpackChunkName: "BatchesList" */"@containers/Batch/BatchesListContainer"));
const CohortDetails = lazy(() =>
  import(/* webpackChunkName: "CohortDetails" */"@containers/Batch/CohortDetailContainer")
);
const CohortMembers = lazy(() =>
  import(/* webpackChunkName: "CohortMembers" */"@containers/Batch/CohortMembersContainer")
);
const StudentReport = lazy(() => import(/* webpackChunkName: "StudentReport" */"@containers/Batch/StudentReportContainer"));
const FacultyReport = lazy(() => import(/* webpackChunkName: "FacultyReport" */"@containers/Batch/FacultyReportContainer"));
const BatchReport = lazy(() => import(/* webpackChunkName: "BatchReport" */"@presentation/BatchReport/"));
const AllBatchesContainer = lazy(() => import(/* webpackChunkName: "AllBatchesContainer" */"@containers/Batch/AllBatchesContainer"));

const UserVerificationContainer = lazy(() => import(/* webpackChunkName: "UserVerificationContainer" */"@containers/Auth/UserVerificationContainer"));

const ManageStudents = lazy(() => import(/* webpackChunkName: "ManageStudents" */"@presentation/CohortDetail/ManageStudentPage"));
const InviteStudents = lazy(() => import(/* webpackChunkName: "InviteStudents" */"@presentation/CohortDetail/ManageStudentPage/Components/InviteStudentPopUP"));
const BatchesListPage = lazy(() => import(/* webpackChunkName: "BatchesListPage" */"@presentation/batch/BatchListPage"));
// Library
const LibraryContainer = lazy(() => import(/* webpackChunkName: "LibraryContainer" */"@containers/Library/LibraryContainer"));
const ContentDetailPageContainer = (lazy(() => import(/* webpackChunkName: "ContentDetailPageContainer" */"@containers/Library/ContentDetailPageContainer")))
const ContentListingPageContainer = lazy(() => import(/* webpackChunkName: "ContentListingPageContainer" */"@containers/Library/ContentListingPageContainer"));
const LibrarySearchPageContainer = lazy(() => import(/* webpackChunkName: "LibrarySearchPageContainer" */"@containers/Library/SearchPageContainer"));
const SettingsContainer = lazy(() => import(/* webpackChunkName: "SettingsContainer" */"@containers/Settings/SettingsContainer"));
const SyncOptionPageContainer = lazy(() => import(/* webpackChunkName: "SyncOptionPageContainer" */"@containers/Settings/SyncOptionContainer"));
const NsdcPageContainer = lazy(() => import(/* webpackChunkName: "NsdcPageContainer" */"@containers/Auth/NsdcContainer"));
const CourseCatalogPageContainer = lazy(() => import(/* webpackChunkName: "CourseCatalogPageContainer" */"@containers/Course/CourseCatalogContainer"));
const AuthenticateContainer = lazy(() => import (/* webpackChunkName: "AuthenticateContainer" */"@containers/Auth/AuthenticateContainer"));
const CareerGuidancePageContainer = lazy(() => import(/* webpackChunkName: "CareerGuidancePageContainer" */"@containers/Career/CareerGuidanceContainer"));
const Forums = lazy(() => import(/* webpackChunkName: "Forums" */"@presentation/Forums"));
const EventDetailPageContainer = lazy(() => import(/*webpackChunkName: "EventDetailPageContainer" */"@containers/Event/EventDetailContainer"));
const GuestCourseDetailContainer = lazy(() => import("@containers/CourseDetailsContainer/GuestCourseDetailContainer"));

const CourseLesson = lazy(() =>
  import("@presentation/CourseDetails/ActivityPage")
);

interface IProps {
  primaryLanguage?: any;
  isAuthenticated: boolean;
  location: any;
  loggedInUserId: string;
  userProfile: ProfileModel;
}

interface IState { }

const setParentRef = (pRef: any) => {
  BrowserScrollService.init(pRef);
};

export const mapStateToProps = (state: any) => {

  const userId = getLoggedInUserId();

  return {
    loggedInUserId: getLoggedInUserId(),
    isAuthenticated: isAuthenticatedSelector(state),
    location: state.router.location,
    userProfile: getProfileByUserIdSelector(state, userId),
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export class WFRouter extends React.Component<IProps, IState> {
  scrollRef: any;

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  public componentDidUpdate?(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {


    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      const guestLang = guestUserLangCode();
      LanguageService.changeLanguage(guestLang);
      Navigator.replace(NavigationUrl.generate(URLs.guest));
    }

    if (this.props.loggedInUserId) {

      const { userProfile } = this.props;

      const prevUserProfile = prevProps.userProfile;

      const prevPath = prevProps?.location?.pathname;
      const currentPath = this.props?.location?.pathname;

      if ((userProfile && !prevUserProfile) || (userProfile && (prevPath !== currentPath))) {
        // check if logged user profile details are complete
        handleAuthFlow(this.props?.location?.pathname, userProfile);
      }
    }
  }



  public render() {
    const { location, isAuthenticated } = this.props;

    const isWebViewValue = !!(window.localStorage.getItem(StorageKeys.IS_WEBVIEW));

    let RouteWrapper: any;
    if (isAuthenticated || isWebViewValue) {
      RouteWrapper = RoutePrivate;
    } else {
      RouteWrapper = RoutePublic;
    }

    const authenticate = isAuthenticated || isWebViewValue;

    const pathToHome = () => (
      <Redirect to={NavigationUrl.generate(URLs.home)} />
    );

    return (
      <div className="app-container">
        <LocalizedRouter RouterComponent={ConnectedRouter} defaultLanguage={i18n.language}>
          <ErrorBoundary>
            <Suspense fallback={<Spinner showFullPage />}>
            <LocalizedSwitch>
            <RoutePrivate
                  exact
                  path={Routes.JobPrepare.jobPrepare}
                  component={JobPrepare}
                  isAuthenticated={authenticate}
                  location={location}
                />
               <RoutePrivate
                  exact
                  path={Routes.Interview.interview}
                  component={Interview}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Interview.interviewHistory}
                  component={InterviewHistory}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Home.home}
                  component={Home}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Forums.forums}
                  component={Forums}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Home.default}
                  component={Home}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Home.home}
                  component={Home}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Auth.ssoLogin}
                  render={(props) => <SsoLogin {...props} />}
                />
                <RoutePublic
                  exact
                  path={Routes.Dashboard.guestDashboard}
                  component={GuestDashboard}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePublic
                  exact
                  path={Routes.Auth.guest}
                  component={GuestView}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.General.tnc}
                  render={(props) => <TnC {...props} />}
                />
                <Route
                  exact
                  path={Routes.General.refreshCookies}
                  render={(props) => <RefreshCookies {...props} />}
                />
                <Route
                  exact
                  path={Routes.General.openCoursesForClient}
                  render={(props) => <OpenCourses {...props} />}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.createBatch}
                  component={(props) => <CreateBatch {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.batches}
                  component={(props) => <BatchesListPage {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.reports}
                  component={(props) => <StudentReport {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.courseReport}
                  component={(props) => <StudentReport {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.batchReports}
                  component={FacultyReport}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.batchReport}
                  component={FacultyReport}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.cohortDetails}
                  component={(props) => <CohortDetails {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.manageStudents}
                  component={ManageStudents}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.inviteStudents}
                  component={InviteStudents}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePublic
                  exact
                  path={Routes.Auth.register}
                  component={(props) => <SelfRegisterContainer {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePublic
                  exact
                  path={Routes.Auth.login}
                  component={(props) => <Login {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Profile.createProfile}
                  render={(props) => <RegisterUser {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.resetPassword}
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.changePassword}
                  render={(props) => <ChangePassword {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.forgotPassword}
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  exact
                  path={Routes.Auth.logout}
                  render={(props) => <Logout {...props} />}
                />
                <Route
                  exact
                  path={Routes.Privacy.tnc}
                  render={(props) => <TnC {...props} />}
                />
                <RoutePrivate
                  path={[`${Routes.Messages.messages}`, `${Routes.Messages.messageThread}`]}
                  exact={true}
                  component={ChatContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />

                <RoutePrivate
                  exact
                  path={Routes.Calendar.calendar}
                  component={CalendarContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />

                <RoutePrivate
                  exact
                  path={Routes.Meeting.meetingDetail}
                  component={MeetingDetailContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.cohortMembers}
                  component={(props) => <CohortMembers {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />

                <RoutePrivate
                  exact
                  path={Routes.Batch.editBatch}
                  component={CreateBatch}
                  isAuthenticated={authenticate}
                  location={location}
                />

                <RoutePrivate
                  exact
                  path={Routes.Profile.profileDetail}
                  component={ProfileContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />

                <Route
                  exact
                  path={Routes.General.refreshCookies}
                  render={(props) => <RefreshCookies {...props} />}
                />
                <RoutePrivate
                  exact
                  path={Routes.Batch.allBatches}
                  component={AllBatchesContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Course.courseDetails}
                  component={CourseDetails}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Course.connectDiscourse}
                  component={ConnectDiscourse}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Dashboard.support}
                  component={(props) => <SupportView {...props} />}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Auth.userVerification}
                  render={(props) => <UserVerificationContainer {...props} />}
                />
              <RoutePrivate
                exact
                path={Routes.Settings.subMenuSettings}
                component={SettingsContainer}
                isAuthenticated={authenticate}
                location={location}
              />

              <RoutePrivate
                exact
                path={Routes.Settings.syncOption}
                component={SyncOptionPageContainer}
                isAuthenticated={authenticate}
                location={location}
              />
              <RoutePrivate
                exact
                path={Routes.Course.courses}
                component={CourseCatalogPageContainer}
                isAuthenticated={authenticate}
                location={location}
              />
              <RouteWrapper
                  exact
                  path={Routes.Library.library}
                  component={LibraryContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RouteWrapper
                  exact
                  path={Routes.Library.contentListPage}
                  component={ContentListingPageContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RouteWrapper
                  exact
                  path={Routes.Library.searchPage}
                  component={LibrarySearchPageContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RouteWrapper
                  exact
                  path={Routes.Library.contentDetail}
                  component={ContentDetailPageContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
              <RoutePrivate
                exact
                path={Routes.Career.career}
                component={CareerGuidancePageContainer}
                isAuthenticated={authenticate}
                location={location}
              />
              <RoutePrivate
                exact
                path={Routes.Event.eventDetail}
                component={EventDetailPageContainer}
                isAuthenticated={authenticate}
                location={location}
              />
                <RoutePublic
                  exact
                  path={Routes.Course.guestCourseDetail}
                  component={GuestCourseDetailContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />

                <Route
                  exact
                  path={Routes.Auth.nsdc}
                  component={NsdcPageContainer}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <RoutePrivate
                  exact
                  path={Routes.Course.courseLmsLesson}
                  component={CourseLesson}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Course.courseLessonMobile}
                  component={CourseLesson}
                  isAuthenticated={authenticate}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Auth.authenticate}
                  component={AuthenticateContainer}
                  location={location}
                />
                <Route
                  exact
                  path={Routes.Auth.typography}
                  component={Typography}
                />
                <Route exact path={Routes.Home.default} render={pathToHome} />
                <Route path={Routes.Home.lang} render={pathToHome} />
                <Redirect to={Routes.General.notFound} />
              </LocalizedSwitch>
            </Suspense>
          </ErrorBoundary>
        </LocalizedRouter>
      </div>
    );

  }

  public componentDidMount = () => {
    const reactNode = this.scrollRef.current;
    setParentRef(reactNode);
  };
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WFRouter));
