import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { Badge, Dropdown, Layout, Menu } from "antd";

import { BrowserScrollService } from "@utils/BrowserScrollService";
import { getMobileOperatingSystem } from "@utils/DeviceInfoUtils";
import { StorageKeys } from "@utils/LocalStorage";
import { Logger } from "@utils/Logger";
import { adjustLanguage, getHmacKey, getLoggedInUserId, getUserEmail, getUserRole, isFeatureEnabled } from "@utils/UserUtils";

import { ILMSSession } from "@common/domain/models/Course";
import { ProfileModel } from "@common/domain/models/Profile";
import { LMS_COOKIES_REMOVE } from "@common/network/constants";
import {
  isAuthenticatedSelector,
  isBetaUser,
  isWebView
} from "@common/redux/auth/selectors";
import { getUnreadConversationCount } from "@common/redux/chat/actions";
import { getUnreadConversationCountSelector } from "@common/redux/chat/selectors";
import { FEATURE_NAME } from "@common/redux/constants";
import { getNotificationsCount } from "@common/redux/notifications/actions";
import { getProfileMap } from "@common/redux/profile/selectors";
import { getUserSettingsConfig } from "@common/redux/settings/actions";
import { getSettingsConfigSelector } from "@common/redux/settings/selectors";

import {
  BarChartOutlined,
  BookOutlined,
  CalendarOutlined,
  FormOutlined,
  HomeOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined
} from "@assets/antdIcons";
import { ChatIcon } from "@assets/images";
import { calendarIcon, homeIcon, libraryIcon, reportsIcon, WF_Logo_Color } from "@assets/index";
import { i18n } from '@translations/i18n';
import { translate } from "@translations/Translater";

import { WFPrimaryButton, WFProfilePic } from "@components/index";
import ContentWrapper from "@components/LayoutContainers/ContentWrapper";
import WFMenuItem from "@components/Menu";
import { WFModal } from "@components/Modal/WFModal";
import Text, { FontFamilyVariant, TextType } from "@components/Text";

import Navigator from "@navigator/index";
import { NavigationUrl, URLs } from "@navigator/NavigationUrl";

import { APP_MENU_ITEM, FEATURE_TOGGLE, Platform, userRoles } from "@constants/config";

import NotificationsContainer from "@containers/Notifications/NotificationsContainer";

import Nudges from "@presentation/Root/Nudges";

import { RiseOutlined } from "@ant-design/icons";
import Config from '@config/index';
import { LayoutUtil } from "@layout/LayoutUtil";
import { layoutMessages } from "@layout/messages";
import DashBoardMenu from "./components/DashBoardMenu";
import TabView from "./components/TabView";
import "./styles.less";

const { Header, Sider, Content } = Layout;

interface IProps {
  component: React.ComponentType;
  location: any;
  isAuthenticated: boolean;
  t: any;
  isWebView?: boolean;
  isBetaUser?: boolean;
  getNotificationCount: (userId: string) => void;
  profileMap?: {};
  getUnreadConversationCount: (userId: string) => void;
  unreadConversationCount: number;
  getSettingsConfig: () => void;
  settingsConfig: any;
  fetchLmsSession: () => void;
  LmsSession: ILMSSession;
  isFetchingLmsSession: boolean;
}

interface IState {
  current: string;
  selectedDesktopMenuKey: string;
  selectedMobileTabKey: string;
  showModal: boolean;
  closeNudge: string;
}

const setParentRef = (pRef: any) => {
  BrowserScrollService.init(pRef);
};

export interface IAppMenuItem {
  url: string;
  name: any;
  key: string;
  icon?: React.ReactNode;
  iconImage?: React.ReactNode;
}


// tslint:disable:no-shadowed-variable


const getAppMenuItemsObject = (profileInfo: ProfileModel) => {

  const APP_MENU_ITEMS = Object.freeze({
    HOME: {
      url: NavigationUrl.generate(URLs.home),
      name: layoutMessages.dashboardMenu,
      key: 'dashboard',
      iconImage: <HomeOutlined />,
      enabled: true,
    },
    BATCHES: {
      url: NavigationUrl.generate(URLs.batches),
      name: layoutMessages.cohortsMenu,
      key: 'batches',
      iconImage: <UsergroupAddOutlined />,
      enabled: true
    },
    STUDENTS: {
      url: NavigationUrl.generate(URLs.batchesStudents),
      name: layoutMessages.studentMenu,
      key: 'student',
      iconImage: <UsergroupAddOutlined />,
      enabled: true
    },
    COURSES: {
      url: NavigationUrl.generate(URLs.courses),
      name: layoutMessages.coursesMenu,
      key: 'courses',
      iconImage: <ReadOutlined />,
      enabled: true
    },
    REPORTS: {
      url: NavigationUrl.generate(URLs.reports),
      name: layoutMessages.reportsMenu,
      key: 'reports',
      iconImage: <BarChartOutlined />,
      enabled: true
    },
    BATCH_REPORTS: {
      url: NavigationUrl.generate(URLs.batchReports),
      name: layoutMessages.reportsMenu,
      key: 'batch-reports',
      iconImage: <BarChartOutlined />,
      enabled: true
    },
    CALENDAR: {
      url: NavigationUrl.generate(URLs.calendar),
      name: layoutMessages.calendarMenu,
      key: 'calendar',
      iconImage: <CalendarOutlined />,
      enabled: true
    },
    ALL_BATCHES: {
      url: NavigationUrl.generate(URLs.allBatches),
      name: layoutMessages.allBatches,
      key: 'allCohorts',
      iconImage: <UsergroupAddOutlined />,
      enabled: true
    },
    COURSE_CATALOG: {
      url: NavigationUrl.generate(URLs.courses),
      name: layoutMessages.coursesMenu,
      key: 'courseCatalog',
      iconImage: <ReadOutlined />,
      enabled: false
    },
    LIBRARY: {
      url: NavigationUrl.generate(URLs.library),
      name: layoutMessages.libraryMenu,
      key: 'library',
      iconImage: <BookOutlined />,
      enabled: true
    },
    INTERVIEW: {
      url: NavigationUrl.generate(URLs.jobPrepare),
      name: layoutMessages.interviewMenu,
      key: 'interview-prep',
      iconImage: <FormOutlined />,
      enabled: false
    },
    FORUMS: {
      url: NavigationUrl.generate(URLs.forums),
      name: layoutMessages.forumsMenu,
      key: 'forums',
      iconImage: <TeamOutlined />,
      enabled: isFeatureEnabled(FEATURE_NAME.FORUM, profileInfo)
    },
    CAREERS: {
      url: NavigationUrl.generate(URLs.career),
      name: layoutMessages.careersMenu,
      key: 'careers',
      iconImage: <RiseOutlined />,
      enabled: isFeatureEnabled(FEATURE_NAME.CAREERS, profileInfo)
    }
  });

  return APP_MENU_ITEMS;
}

const createSidebarItems = (persona, profileInfo, APP_MENU_ITEMS) => {

  const betaFeatures: string[] = profileInfo?.getBetaFeatures();
  const items = [];
  for (const i in APP_MENU_ITEMS) {
    if (APP_MENU_ITEMS[i].enabled && persona.includes(APP_MENU_ITEMS[i])) {
      items.push(APP_MENU_ITEMS[i]);
    }
  }
  return items;
}

export const getMenuItems = (role: userRoles | string, profileInfo: ProfileModel): IAppMenuItem[] => {

  const APP_MENU_ITEMS = getAppMenuItemsObject(profileInfo);

  const student = [APP_MENU_ITEMS.HOME, APP_MENU_ITEMS.CALENDAR, APP_MENU_ITEMS.COURSE_CATALOG, APP_MENU_ITEMS.INTERVIEW, APP_MENU_ITEMS.FORUMS, APP_MENU_ITEMS.CAREERS, APP_MENU_ITEMS.LIBRARY];
  const faculty = [APP_MENU_ITEMS.HOME, APP_MENU_ITEMS.BATCHES, APP_MENU_ITEMS.CALENDAR, APP_MENU_ITEMS.BATCH_REPORTS, APP_MENU_ITEMS.COURSE_CATALOG,  APP_MENU_ITEMS.LIBRARY];
  const regionalManager = [APP_MENU_ITEMS.HOME, APP_MENU_ITEMS.CALENDAR, APP_MENU_ITEMS.BATCH_REPORTS, APP_MENU_ITEMS.ALL_BATCHES, APP_MENU_ITEMS.COURSE_CATALOG,  APP_MENU_ITEMS.LIBRARY];

  switch (role) {
    case userRoles.student: return createSidebarItems(student, profileInfo, APP_MENU_ITEMS);
    case userRoles.faculty: return createSidebarItems(faculty, profileInfo, APP_MENU_ITEMS);
    case userRoles.regionalManager: return createSidebarItems(regionalManager, profileInfo, APP_MENU_ITEMS);
    default: return [APP_MENU_ITEMS.HOME];
  }
};

class Dashboard extends React.Component<IProps, IState> {
  scrollRef: any;

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  private getSideMenuItems = (): IAppMenuItem[] => {
    const { t, profileMap } = this.props;
    const profileInfo: ProfileModel = profileMap[profileMap[getLoggedInUserId()]];
    const menuItems: IAppMenuItem[] = getMenuItems(getUserRole(), profileInfo);
    return menuItems.map((menuItem: IAppMenuItem) => {
      return {
        ...menuItem,
        name: translate(t, menuItem.name)
      }
    })
  };

  private launchUserSupport = () => {
    const { profileMap } = this.props;
    const profileInfo: ProfileModel = profileMap[profileMap[getLoggedInUserId()]];

    if (profileInfo) {
      let lang = adjustLanguage(profileInfo?.getPreferredLanguage());
      let oAuthId = Config.FRESHDESK_WEB_CONFIG.oAuthId;
      window.open(`https://wgdt-wadhwanifoundation-support.freshdesk.com/${lang}/support/home?redirection=https%3A%2F%2Fwadhwani-foundation.myfreshworks.com%2Fsp%2FOAUTH%2F${oAuthId}%2Flogin%3Fredirect_uri%3Dhttps%3A%2F%2Fwadhwani-foundation.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%253Dwgdt-wadhwanifoundation-support.freshdesk.com%26client_id%3D451980303908285122%26slug%3D1737362616922`)
    } else {
      this.setState({ showModal: true });
    }
  }

  private getInitialState = (): IState => {
    const pathName = this.props.location.pathname;
    const menuKey = LayoutUtil.getMenuByPathName(pathName, this.getSideMenuItems());

    return {
      selectedDesktopMenuKey: menuKey,
      selectedMobileTabKey: menuKey,
      current: "",
      showModal: false,
      closeNudge: localStorage.getItem(StorageKeys.SHOW_APP_NUDGE),
    };

  }

  state = this.getInitialState();


  public async componentDidMount() {
    this.props.getSettingsConfig();
    const userId = getLoggedInUserId();
    const reactNode = this.scrollRef.current;
    this.setDefaultSelectedMenu();
    setParentRef(reactNode);
    if (userId) {
      this.props.getNotificationCount(userId);
      this.props.getUnreadConversationCount(userId);
    }
  }

  public onClosingNudge = (payload) => {
    this.setState({ closeNudge: payload });
  }




  render() {
    const { isWebView } = this.props;

    const nudgeClass = (!(localStorage.getItem(StorageKeys.SHOW_APP_NUDGE) === "false" || this.state.closeNudge === "false") && !isWebView) ? "app__layout__outer_content_nudges" : "";

    if (isWebView)
      return (
        <Content className="app__layout__inner_content_webview">
          {this.renderContent()}
        </Content>
      );

    return (
      <ContentWrapper className="layout_wrapper">
        <Layout className="app__layout__dash">
          <Header className="layout_header">{this.renderHeader()}</Header>
          {!isWebView && <Nudges onClick={this.onClosingNudge} />}
          <Layout className={`app__layout__outer_content ${nudgeClass}`}>
            {/* <Sider className="app_layout_sider">
              {this.renderMenu()}
            </Sider> */}
            <Content className="app__layout__inner_content"
              id="app__layout__inner_content">
              {this.renderContent()}
            </Content>
            <div className="app_layout_sm_tab">
              {this.renderTabs()}
            </div>
          </Layout>
        </Layout>
      </ContentWrapper>
    );
  }

  private renderContent = () => {
    const Component = this.props.component;
    return <Component {...this.props} />;
  };

  private setSelectedMenu = (selectedKey: string) => {
    this.setState({
      selectedDesktopMenuKey: selectedKey,
      selectedMobileTabKey: selectedKey,
    });
  };

  private setDefaultSelectedMenu = () => {
    const pathName = this.props.location.pathname;
    const menuKey = LayoutUtil.getMenuByPathName(pathName, this.getSideMenuItems());

    this.setState({
      selectedDesktopMenuKey: menuKey,
      selectedMobileTabKey: menuKey,
    });
  };


  private renderMenu = () => {
    const loggedInUserrole = localStorage.getItem(StorageKeys.USER_ROLE);
    const menuItems: IAppMenuItem[] = this.getSideMenuItems();
    return (
      <DashBoardMenu
        onMenuChange={this.setSelectedMenu}
        selectedKey={this.state.selectedDesktopMenuKey}
        menuItems={menuItems}
      />
    );
  };

  private launchTicketModal = () => {
    this.setState({ showModal: true });
  }

  private renderTicketModal = () => {
    const { profileMap } = this.props;
    const profileInfo: ProfileModel = profileMap[profileMap[getLoggedInUserId()]];
    const userEmail = getUserEmail();
    const hmacKey = getHmacKey(Config.CRISP_WEB_CONFIG.hmac_secret, userEmail);
    const profileName = profileInfo?.getName() || "";
    return (
      <WFModal
        centered
        showModal={this.state.showModal}
        onCancel={() => { this.setState({ showModal: false }) }}
      >
        <iframe
          title="Ticket Center"
          src={`https://plugins.crisp.chat/urn:crisp.im:ticket-center:0/tickets/577abe07-4cb8-4a99-8042-d211534a967b?email=${userEmail}&hmac=${hmacKey}&nickname=${profileName}&locale=${profileInfo?.getPreferredLanguage()}`}
          referrerPolicy="origin"
          sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
          width="100%"
          height="600px"

          frameBorder="0">
        </iframe>
      </WFModal>
    );
  }

  private supportIcon = () => {
    const { t } = this.props;
    return (
      <div className={`report_button`}>
        <WFPrimaryButton htmlType={'button'} onClick={() => {
          this.launchUserSupport();

        }}>
          {translate(t, layoutMessages.help)}
        </WFPrimaryButton>
      </div>
    );
  }

  private renderTabs = () => {
    const { isBetaUser } = this.props;
    const loggedInUserrole = localStorage.getItem(StorageKeys.USER_ROLE);
    const tabtems: IAppMenuItem[] = this.getSideMenuItems();
    // code to exclude any particular tab item

    return (
      <TabView
        activeKey={this.state.selectedMobileTabKey}
        onChange={this.setSelectedMenu}
        tabItems={tabtems}
      />
    );
  };

  private renderLogo = () => {
    return (
      <div onClick={null} className="layout_logo">
        <img alt="wadhwani logo white color" src={WF_Logo_Color} width={80} />
        {/* ?\ remove moodle cookies */}
        <img src={LMS_COOKIES_REMOVE} width="0" height="0"></img>
      </div>
    );
  };

  private renderHeader = () => {
    return (
      <>
        {this.renderLogo()}
        {this.renderHeaderMenu()}
      </>
    );
  };

  private renderHeaderMenu = () => {
    const { t, profileMap, unreadConversationCount, settingsConfig } = this.props;
    const USER_OPTIONS: string[] = [
      translate(t, layoutMessages.myProfileTitle),
      translate(t, layoutMessages.help)
    ].concat((FEATURE_TOGGLE.userSettings && settingsConfig?.config?.length > 0) ?
      [translate(t, layoutMessages.settingsTitle)] : []);
    USER_OPTIONS.push(translate(t, layoutMessages.logoutTitle));;

    const profileInfo: ProfileModel = profileMap[profileMap[getLoggedInUserId()]];
    const profileImg = profileInfo?.getProfileImageUrl();

    return (
      <div className="header_menu_container">
        {this.renderMenu()}
        <div className="chat_container">
        {this.supportIcon()}
          {getUserRole() !== userRoles.instituteAdmin && (<div onClick={this.gotoChat}>
            <Badge className="chat_badge" count={unreadConversationCount > 0 ? unreadConversationCount : 0} >
              <img src={ChatIcon} width={20} className="menu-icons" onClick={this.gotoChat}/>
            </Badge>
          </div>)}
          <div onClick={null} className="notification_container">
            <NotificationsContainer />
          </div>
          <Dropdown overlay={this.userOptions(USER_OPTIONS)} trigger={["click"]}>
            <div className="ml-3 profile_container" onClick={this.avoidDefault}>
              <WFProfilePic
                name={profileInfo?.getName()}
                imageUrl={profileImg}
                retry={{ count: 0 }}
                className="pro_avatar__avatar"
                placeholder={profileInfo?.getName()}
                initialsClassName={"empty_pic"}
                size={100}
                shape="square"
              />
            </div>
          </Dropdown>
        </div>
      </div>
    );
  };

  private gotoChat = () => {
    Navigator.push(NavigationUrl.generate(URLs.messages));
  };

  private goToCalendar = () => {
    Navigator.push(NavigationUrl.generate(URLs.calendar));
  };

  private logOut = () => {
    Navigator.push(NavigationUrl.generate(URLs.logout));
  };

  private openUserProfile = () => {
    const url = NavigationUrl.generate(URLs.profile, {
      userId: getLoggedInUserId(),
    });
    Navigator.push(url);
  };
  private settings = () => {
    const { settingsConfig } = this.props;
    const url = settingsConfig?.config && settingsConfig?.config[0]?.key;
    Navigator.push(NavigationUrl.generate(URLs.settingsMenu, { subMenu: url }));
  }
  private menuItem = (key, value) => {
    const { t } = this.props;

    let icon = null;
    if (value === translate(t, layoutMessages.myProfileTitle)) {
      icon = <UserOutlined className="header-drop-down-icons" />;
    } else if (value === translate(t, layoutMessages.logoutTitle)) {
      icon = <LogoutOutlined className="header-drop-down-icons" />;
    } else if (value === translate(t, layoutMessages.help)) {
      icon = <QuestionCircleOutlined className="header-drop-down-icons" />;
    } else if (value === translate(t, layoutMessages.settingsTitle)) {
      icon = <SettingOutlined />;
    }

    return (
      <WFMenuItem key={value}>
        <div className="app__layout_menu_item">
          {icon}
          <Text
            textType={TextType.subHeading1}
            fontFamilyVariant={FontFamilyVariant.semibold}
            text={value}
            className="menu_dropdown_style"
          />
        </div>
      </WFMenuItem>
    );
  };

  private userOptions = (USER_OPTIONS: string[]) => {
    const userOptions = USER_OPTIONS.map((item: string, index: number) =>
      this.menuItem(index, item)
    );
    return <Menu onClick={this.handleUserOptionSelection}>{userOptions}</Menu>;
  };

  private avoidDefault = (e) => e.preventDefault();

  private handleUserOptionSelection = ({ key }) => {
    const { t } = this.props;

    if (key === translate(t, layoutMessages.myProfileTitle)) {
      this.openUserProfile();
    } else if (key === translate(t, layoutMessages.logoutTitle)) {
      this.logOut();
    } else if (key === translate(t, layoutMessages.help)) {
      this.launchTicketModal();
    } else if (key === translate(t, layoutMessages.settingsTitle)) {
      this.settings();
    } else {
      Logger.info(
        "Dashboard - handleUserOptionSelection() Option not handled - " + key
      );
    }
  };
}

export const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: isAuthenticatedSelector(state),
    location: state.router.location,
    isWebView: isWebView(state),
    isBetaUser: isBetaUser(state),
    profileMap: getProfileMap(state),
    unreadConversationCount: getUnreadConversationCountSelector(state),
    settingsConfig: getSettingsConfigSelector(state)
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    getUnreadConversationCount: (userId: string) => dispatch(getUnreadConversationCount(userId)),
    getNotificationCount: (userId: string) => { dispatch(getNotificationsCount(userId)) },
    getSettingsConfig: () => dispatch(getUserSettingsConfig()),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(withRouter(Dashboard));
