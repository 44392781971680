import { IPreferences, LoginModel } from "@common/domain/models/Login";

import { IMaintenanceErrorPayload, IUserExists } from '@redux/auth/interface';

import { IState } from "@store/webInterfaces";

const isAuthStateUndefined = (state: IState) => state.auth === undefined;

const isAuthErrorsUndefined = (state: IState) => state.auth.errors === undefined;

const isAuthLoaderUndefined = (state: IState) => state.auth.loaders === undefined;

export const loginSelector = (state: IState): LoginModel => {
    if(isAuthStateUndefined(state) || !state.auth.login)
        return null;
    return state.auth.login as LoginModel;
}

export const getLogoutLoaderSelector = (state: IState) => state?.auth?.loaders?.logout;

export const getLogoutErrorSelector = (state: IState) => state?.auth?.errors?.logout;

export const userPreferencesSelector = (state: IState): IPreferences => {
    const login = loginSelector(state);
    if(login)
        return null;
    const preferences = login.preferences;
    if(preferences) {
        return preferences as IPreferences;
    }
    return null;
};

export const userPersona = (state: IState): string => {
    const login = loginSelector(state);
    if(!login)
        return '';
    if(login.role && login.role.length > 0){
        return login.role[0];
    }
    return '';
}

export const loginLoaderSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || isAuthLoaderUndefined(state) || !state.auth.loaders.login)
        return false;
    return state.auth.loaders.login;
}

export const loginErrorSelector = (state: IState): string => {
    if(isAuthStateUndefined(state) || isAuthErrorsUndefined(state) || !state.auth.errors.login)
        return null;
    return state.auth.errors.login;
}

export const checkUserExistSelector = (state: IState): IUserExists => {
    if(isAuthStateUndefined(state) || !state.auth.checkUserExist)
        return null;
    return state.auth.checkUserExist as IUserExists;
}

export const checkUserExistLoaderSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || isAuthLoaderUndefined(state) || !state.auth.loaders.checkUserExist)
        return false;
    return state.auth.loaders.checkUserExist;
}

export const checkUserExistErrorSelector = (state: IState): string => {
    if(isAuthStateUndefined(state) || isAuthErrorsUndefined(state) || !state.auth.errors.checkUserExist)
        return null;
    return state.auth.errors.checkUserExist;
}

export const registerSelector = (state: IState): any => {
    if(isAuthStateUndefined(state) || !state.auth.register)
        return null;
    return state.auth.register;
}

export const registerLoaderSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || isAuthLoaderUndefined(state) || !state.auth.loaders.register)
        return false;
    return state.auth.loaders.register;
}

export const registerErrorSelector = (state: IState): string => {
    if(isAuthStateUndefined(state) || isAuthErrorsUndefined(state) || !state.auth.errors.register)
        return '';
    return state.auth.errors.register;
}

export const resendVerifyMailSelector = (state: IState): any => {
    if(isAuthStateUndefined(state) || !state.auth.resendVerifyMail)
        return null;
    return state.auth.resendVerifyMail;
}

export const resendVerifyMailLoaderSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || isAuthLoaderUndefined(state) || !state.auth.loaders.resendVerifyMail)
        return false;
    return state.auth.loaders.resendVerifyMail;
}

export const resendVerifyMailErrorSelector = (state: IState): string => {
    if(isAuthStateUndefined(state) || isAuthErrorsUndefined(state) || !state.auth.errors.resendVerifyMail)
        return '';
    return state.auth.errors.resendVerifyMail;
}

export const forgotPasswordSelector = (state: IState): any => {
    if(isAuthStateUndefined(state) || !state.auth.forgotPassword)
        return null;
    return state.auth.forgotPassword;
}

export const forgotPasswordLoaderSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || isAuthLoaderUndefined(state) || !state.auth.loaders.forgotPassword)
        return false;
    return state.auth.loaders.forgotPassword;
}

export const forgotPasswordErrorSelector = (state: IState): string => {
    if(isAuthStateUndefined(state) || isAuthErrorsUndefined(state) || !state.auth.errors.forgotPassword)
        return '';
    return state.auth.errors.forgotPassword;
}

export const changePasswordSelector = (state: IState): any => {
    if(isAuthStateUndefined(state) || !state.auth.changePassword)
        return null;
    return state.auth.changePassword;
}

export const changePasswordLoaderSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || isAuthLoaderUndefined(state) || !state.auth.loaders.changePassword)
        return false;
    return state.auth.loaders.changePassword;
}

export const changePasswordErrorSelector = (state: IState): string => {
    if(isAuthStateUndefined(state) || isAuthErrorsUndefined(state) || !state.auth.errors.changePassword)
        return '';
    return state.auth.errors.changePassword;
}

export const isAuthenticatedSelector = (state: any): boolean => {
    if ( state.auth === undefined || state.auth.isAuthenticated === undefined ) {
      return false;
    }
    return state.auth.isAuthenticated;
};

export const isWebView = (state: any): boolean => {
    if ( state.auth === undefined || state.auth.isWebView === undefined ) {
      return false;
    }
    return state.auth.isWebView;
};

export const resetPasswordSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || !state.auth.resetPassword)
        return null;
    return state.auth.resetPassword;
}

export const resetPasswordLoaderSelector = (state: IState): boolean => {
    if(isAuthStateUndefined(state) || isAuthLoaderUndefined(state) || !state.auth.loaders.resetPassword)
        return false;
    return state.auth.loaders.resetPassword;
}

export const resetPasswordErrorSelector = (state: IState): string => {
    if(isAuthStateUndefined(state) || isAuthErrorsUndefined(state) || !state.auth.errors.resetPassword)
        return '';
    return state.auth.errors.resetPassword;
}

export const getIsFirstTimeUserSelector = (state: IState): boolean => state?.auth?.isFirstTimeUser;

// email verifcation
export const emailVerficationLoaderSelector = (state: IState): boolean => state?.auth?.loaders?.emailVerification || false;
export const emailVerficationErrorSelector = (state: IState): string => state?.auth?.errors?.emailVerification || "";
export const emailVerficationSelector = (state: IState): string => state?.auth?.emailVerification || "";

export const maintenanceModeSelector = (state: IState): IMaintenanceErrorPayload => state?.auth?.maintenance || null;

// NSDC Flow
export const getNsdcSsoLoginUrlSelector = (state: IState) => {
    return state?.auth?.nsdcSsoData || null;
}

export const getNsdcSsoLoginUrlErrorSelector = (state: IState) => {
    return state?.auth?.errors?.nsdcSsoData || "";
}

export const getNsdcSsoLoginUrlLoaderSelector = (state: IState) => {
    return state?.auth?.loaders?.nsdcSsoData || false;
}
export const isBetaUser = (state: any): boolean => {
    if ( state.auth === undefined || state.auth.isBetaUser === undefined ) {
      return false;
    }
    return state.auth.isBetaUser;
};

export const authoriseSsoUserLoaderSelector = (state: IState): boolean => state?.auth?.loaders?.authoriseSsoUser || false;
export const authoriseSsoUserErrorSelector = (state: IState): string => state?.auth?.errors?.authoriseSsoUser || "";
export const authoriseSsoUserSelector = (state: IState): {} => state?.auth?.authoriseSsoUser || {};
